import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { ptBR } from "date-fns/locale";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Label } from "../../../components/Input/styles";
import SearchAucomplete from "../../../components/SearchAutocomplete";
import { API_BASE_URL } from "../../../config/contants";
import secure from "../../../config/secureLS";
import { useLoading } from "../../../hooks/useLoading";
import { api } from "../../../services/api";
import type { Datasheet } from "../../fichas-tecnicas/[id]/index.page";
import { ButtonContainer } from "../../fichas-tecnicas/styles";
import { Container, Form } from "./styles";
import { formatStringPlayer } from "../../../helpers";
import _ from "lodash";
interface OptionsProps {
  name: string;
  image?: string;
}

interface PlayersProps {
  name: string;
  description: string;
}

interface IData {
  opponentsData?: {
    data: OptionsProps[];
  };
  coachesData?: {
    data: OptionsProps[];
  };
  coachesOpponentsData?: {
    data: OptionsProps[];
  };
  refereesData?: {
    data: OptionsProps[];
  };
  assistantData?: {
    data: OptionsProps[];
  };
  secondAssistantData?: {
    data: OptionsProps[];
  };
  playersData: {
    data: PlayersProps[];
  };
  stadiumsData?: {
    data: OptionsProps[];
  };
  championshipsData?: {
    data: OptionsProps[];
  };
  stagesData?: {
    data: OptionsProps[];
  };
  countriesData?: {
    data: OptionsProps[];
  };
}

interface FormFields {
  id: number | null;
  day: string;
  month: string;
  year: string;
  time2: string;
  tecnico: string;
  tecnico2: string;
  juiz: string;
  auxiliar1: string;
  auxiliar2: string;
  estadio: string;
  tipo: string;
  etapa: string;
  pais: string;
  placar1: string;
  placar2: string;
  localdojogo: string;
  periodo: string;
  data: string;
  comparadata: string;
  campeao: string;
  escalat1: string;
  jogador1t1: string;
  jogador2t1: string;
  jogador3t1: string;
  jogador4t1: string;
  jogador5t1: string;
  jogador6t1: string;
  jogador7t1: string;
  jogador8t1: string;
  jogador9t1: string;
  jogador10t1: string;
  jogador11t1: string;
  obs1p: string;
  obs2p: string;
  obs3p: string;
  obs4p: string;
  obs5p: string;
  obs6p: string;
  obs7p: string;
  obs8p: string;
  obs9p: string;
  obs10p: string;
  obs11p: string;
  escalat2: string;
  jogador1t2: string;
  jogador2t2: string;
  jogador3t2: string;
  jogador4t2: string;
  jogador5t2: string;
  jogador6t2: string;
  jogador7t2: string;
  jogador8t2: string;
  jogador9t2: string;
  jogador10t2: string;
  jogador11t2: string;
  obs1a: string;
  obs2a: string;
  obs3a: string;
  obs4a: string;
  obs5a: string;
  obs6a: string;
  obs7a: string;
  obs8a: string;
  obs9a: string;
  obs10a: string;
  obs11a: string;
  comentario: string;
  publico: string;
}

const FichasTecnicas: React.FC = () => {
  const [subs, setSubs] = React.useState([""]);
  const [expanded, setExpanded] = React.useState<string | false>("");
  const { showLoading, closeLoading, loading } = useLoading();
  const [optionsForm, setOptionsForm] = React.useState<IData | null>();
  const editorRef = React.useRef<any>(null);
  const [sheet, setSheet] = React.useState<Datasheet | null>();

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [formFields, setFormFields] = React.useState<
    FormFields & Record<string, any>
  >({
    id: null,
    day: "",
    month: "",
    year: "",
    time2: "",
    tecnico: "",
    tecnico2: "",
    juiz: "",
    auxiliar1: "",
    auxiliar2: "",
    estadio: "",
    tipo: "",
    etapa: "",
    pais: "",
    placar1: "",
    placar2: "",
    localdojogo: "",
    periodo: "",
    data: "",
    campeao: "",
    comparadata: "",
    escalat1: "",
    jogador1t1: "",
    jogador2t1: "",
    jogador3t1: "",
    jogador4t1: "",
    jogador5t1: "",
    jogador6t1: "",
    jogador7t1: "",
    jogador8t1: "",
    jogador9t1: "",
    jogador10t1: "",
    jogador11t1: "",
    obs1p: "",
    obs2p: "",
    obs3p: "",
    obs4p: "",
    obs5p: "",
    obs6p: "",
    obs7p: "",
    obs8p: "",
    obs9p: "",
    obs10p: "",
    obs11p: "",
    escalat2: "",
    jogador1t2: "",
    jogador2t2: "",
    jogador3t2: "",
    jogador4t2: "",
    jogador5t2: "",
    jogador6t2: "",
    jogador7t2: "",
    jogador8t2: "",
    jogador9t2: "",
    jogador10t2: "",
    jogador11t2: "",
    obs1a: "",
    obs2a: "",
    obs3a: "",
    obs4a: "",
    obs5a: "",
    obs6a: "",
    obs7a: "",
    obs8a: "",
    obs9a: "",
    obs10a: "",
    obs11a: "",
    player1psub1: "",
    player1psub2: "",
    player1psub3: "",
    player2psub1: "",
    player2psub2: "",
    player2psub3: "",
    player3psub1: "",
    player3psub2: "",
    player3psub3: "",
    player4psub1: "",
    player4psub2: "",
    player4psub3: "",
    player5psub1: "",
    player5psub2: "",
    player5psub3: "",
    player6psub1: "",
    player6psub2: "",
    player6psub3: "",
    player7psub1: "",
    player7psub2: "",
    player7psub3: "",
    player8psub1: "",
    player8psub2: "",
    player8psub3: "",
    player9psub1: "",
    player9psub2: "",
    player9psub3: "",
    player10psub1: "",
    player10psub2: "",
    player10psub3: "",
    player11psub1: "",
    player11psub2: "",
    player11psub3: "",
    player1psub1obs: "",
    player1psub2obs: "",
    player1psub3obs: "",
    player2psub1obs: "",
    player2psub2obs: "",
    player2psub3obs: "",
    player3psub1obs: "",
    player3psub2obs: "",
    player3psub3obs: "",
    player4psub1obs: "",
    player4psub2obs: "",
    player4psub3obs: "",
    player5psub1obs: "",
    player5psub2obs: "",
    player5psub3obs: "",
    player6psub1obs: "",
    player6psub2obs: "",
    player6psub3obs: "",
    player7psub1obs: "",
    player7psub2obs: "",
    player7psub3obs: "",
    player8psub1obs: "",
    player8psub2obs: "",
    player8psub3obs: "",
    player9psub1obs: "",
    player9psub2obs: "",
    player9psub3obs: "",
    player10psub1obs: "",
    player10psub2obs: "",
    player10psub3obs: "",
    player11psub1obs: "",
    player11psub2obs: "",
    player11psub3obs: "",
    player1asub1: "",
    player1asub2: "",
    player1asub3: "",
    player2asub1: "",
    player2asub2: "",
    player2asub3: "",
    player3asub1: "",
    player3asub2: "",
    player3asub3: "",
    player4asub1: "",
    player4asub2: "",
    player4asub3: "",
    player5asub1: "",
    player5asub2: "",
    player5asub3: "",
    player6asub1: "",
    player6asub2: "",
    player6asub3: "",
    player7asub1: "",
    player7asub2: "",
    player7asub3: "",
    player8asub1: "",
    player8asub2: "",
    player8asub3: "",
    player9asub1: "",
    player9asub2: "",
    player9asub3: "",
    player10asub1: "",
    player10asub2: "",
    player10asub3: "",
    player11asub1: "",
    player11asub2: "",
    player11asub3: "",
    player1asub1obs: "",
    player1asub2obs: "",
    player1asub3obs: "",
    player2asub1obs: "",
    player2asub2obs: "",
    player2asub3obs: "",
    player3asub1obs: "",
    player3asub2obs: "",
    player3asub3obs: "",
    player4asub1obs: "",
    player4asub2obs: "",
    player4asub3obs: "",
    player5asub1obs: "",
    player5asub2obs: "",
    player5asub3obs: "",
    player6asub1obs: "",
    player6asub2obs: "",
    player6asub3obs: "",
    player7asub1obs: "",
    player7asub2obs: "",
    player7asub3obs: "",
    player8asub1obs: "",
    player8asub2obs: "",
    player8asub3obs: "",
    player9asub1obs: "",
    player9asub2obs: "",
    player9asub3obs: "",
    player10asub1obs: "",
    player10asub2obs: "",
    player10asub3obs: "",
    player11asub1obs: "",
    player11asub2obs: "",
    player11asub3obs: "",
    comentario: "",
    publico: "",
  });

  console.log(formFields);

  React.useEffect(() => {
    getGameInfo();
  }, []);

  React.useEffect(() => {
    getEditInfo();
  }, [id]);

  const getEditInfo = async () => {
    try {
      if (!id) return;

      const { data: datasheetData } = await api.get(`/datasheet/${id}`);
      setSheet(datasheetData.data);

      const substitutos = [""];

      const palmeiras =
        datasheetData.data?.lineup_palmeiras?.filter(
          (player: any) => !player.isReplacement
        ) ?? [];
      let formatPlayert1: any = {};
      palmeiras.map((player: any) => {
        const informations = formatStringPlayer(player);
        let namePlayer = "";
        let obsPlayer = "";
        if (player.name.split("|") > 1) {
          namePlayer = player.name.split("|")[0];
          obsPlayer = player.name.split("|")[1];
        } else {
          namePlayer = player.name;
        }

        formatPlayert1[`p${player.position}`] = {
          name:
            namePlayer +
            (informations.goals || informations.cards
              ? `*${informations.cards + informations.goals}`
              : ""),
          obs: obsPlayer,
        };
      });

      const palmeirasSubs: any =
        datasheetData.data?.lineup_palmeiras?.filter(
          (player: any) => player.isReplacement
        ) ?? [];
      const palmeirasSubsByPosition: any = _.groupBy(
        palmeirasSubs,
        ({ position }: any) => position
      );

      let formatPlayert1Sub: any = {};
      if (palmeirasSubsByPosition) {
        Object.values(palmeirasSubsByPosition).forEach((item: any) => {
          item.map((player: any, key: any) => {
            console.log("posição: ", player);

            const informations = formatStringPlayer(player);
            let namePlayer = "";
            let obsPlayer = "";
            if (player.name.split("|") > 1) {
              namePlayer = player.name.split("|")[0];
              obsPlayer = player.name.split("|")[1];
            } else {
              namePlayer = player.name;
            }

            console.log("posição: ", player.position);

            formatPlayert1Sub[`${player.position}p${key + 1}`] = {
              name:
                namePlayer +
                (informations.goals || informations.cards
                  ? `*${informations.cards + informations.goals}`
                  : ""),
              obs: obsPlayer,
            };
          });
        });
      }

      const adversario =
        datasheetData.data?.lineup_adversary?.filter(
          (player: any) => !player.isReplacement
        ) ?? [];
      let formatPlayert2: any = {};
      adversario.map((player: any) => {
        const informations = formatStringPlayer(player);
        let namePlayer = "";
        let obsPlayer = "";
        if (player.name.split("|") > 1) {
          namePlayer = player.name.split("|")[0];
          obsPlayer = player.name.split("|")[1];
        } else {
          namePlayer = player.name;
        }

        formatPlayert2[`p${player.position}`] = {
          name:
            namePlayer +
            (informations.goals || informations.cards
              ? `*${informations.cards + informations.goals}`
              : ""),
          obs: obsPlayer,
        };
      });

      const adversarioSubs: any =
        datasheetData.data?.lineup_adversary?.filter(
          (player: any) => player.isReplacement
        ) ?? [];
      const adversarioSubsByPosition: any = _.groupBy(
        adversarioSubs,
        ({ position }: any) => position
      );
      let formatPlayert2Sub: any = {};

      if (adversarioSubsByPosition) {
        Object.values(adversarioSubsByPosition).forEach((item: any) => {
          item.map((player: any, key: any) => {
            const informations = formatStringPlayer(player);
            let namePlayer = "";
            let obsPlayer = "";
            if (player.name.split("|") > 1) {
              namePlayer = player.name.split("|")[0];
              obsPlayer = player.name.split("|")[1];
            } else {
              namePlayer = player.name;
            }

            formatPlayert2Sub[`${player.position}p${key + 1}`] = {
              name:
                namePlayer +
                (informations.goals || informations.cards
                  ? `*${informations.cards + informations.goals}`
                  : ""),
              obs: obsPlayer,
            };
          });
        });
      }

      palmeirasSubs?.map((player: any, key: number) => {
        const myVariable = `player${player.position}psub`;
        const myReg = new RegExp(`${myVariable}.*`);

        const quantitySub = substitutos.filter((name) => name.match(myReg));

        if (quantitySub.length > 0) {
          substitutos.push(`player${player.position}psub${quantitySub.length}`);
          return;
        }

        substitutos.push(`player${player.position}psub`);
      });

      adversarioSubs?.map((player: any, key: number) => {
        const myVariable = `player${player.position}asub`;
        const myReg = new RegExp(`${myVariable}.*`);

        const quantitySub = substitutos.filter((name) => name.match(myReg));

        if (quantitySub.length > 0) {
          substitutos.push(`player${player.position}asub${quantitySub.length}`);
          return;
        }

        substitutos.push(`player${player.position}asub`);
      });

      if (substitutos.length > 1) {
        setSubs(substitutos);
      }

      console.log("Player: ", formatPlayert1Sub);

      setFormFields({
        id: +id,
        day: "",
        month: "",
        year: "",
        time2: datasheetData.data?.game_data.name_opponent ?? "",
        tecnico: datasheetData.data?.game_data.coach_palmeiras ?? "",
        tecnico2: datasheetData.data?.game_data.coach_adversary ?? "",
        juiz: datasheetData.data?.game_data.referee ?? "",
        auxiliar1: datasheetData.data?.game_data.assistant ?? "",
        auxiliar2: datasheetData.data?.game_data.second_assistant ?? "",
        estadio: datasheetData.data?.game_data.stadium ?? "",
        tipo: datasheetData.data?.game_data.championship ?? "",
        etapa: datasheetData.data?.game_data.stage ?? "",
        pais: "",
        placar1: datasheetData.data?.game_data.score_palmeiras ?? "",
        placar2: datasheetData.data?.game_data.score_adversary ?? "",
        localdojogo: datasheetData.data?.game_data.local ?? "",
        periodo: datasheetData.data?.game_data.period ?? "",
        data: datasheetData.data?.game_data.date ?? "",
        campeao: datasheetData.data?.game_data.campeao ?? "n",
        comparadata: datasheetData.data?.game_data.date
          ? datasheetData.data?.game_data.date.replace("/", "")
          : "",
        escalat1: datasheetData.data?.game_data.escalat1,
        jogador1t1: formatPlayert1?.p1?.name ?? "",
        jogador2t1: formatPlayert1?.p2?.name ?? "",
        jogador3t1: formatPlayert1?.p3?.name ?? "",
        jogador4t1: formatPlayert1?.p4?.name ?? "",
        jogador5t1: formatPlayert1?.p5?.name ?? "",
        jogador6t1: formatPlayert1?.p6?.name ?? "",
        jogador7t1: formatPlayert1?.p7?.name ?? "",
        jogador8t1: formatPlayert1?.p8?.name ?? "",
        jogador9t1: formatPlayert1?.p9?.name ?? "",
        jogador10t1: formatPlayert1?.p10?.name ?? "",
        jogador11t1: formatPlayert1?.p11?.name ?? "",
        obs1p: formatPlayert1?.p1?.obs ?? "",
        obs2p: formatPlayert1?.p2?.obs ?? "",
        obs3p: formatPlayert1?.p3?.obs ?? "",
        obs4p: formatPlayert1?.p4?.obs ?? "",
        obs5p: formatPlayert1?.p5?.obs ?? "",
        obs6p: formatPlayert1?.p6?.obs ?? "",
        obs7p: formatPlayert1?.p7?.obs ?? "",
        obs8p: formatPlayert1?.p8?.obs ?? "",
        obs9p: formatPlayert1?.p9?.obs ?? "",
        obs10p: formatPlayert1?.p10?.obs ?? "",
        obs11p: formatPlayert1?.p11?.obs ?? "",
        escalat2: datasheetData.data?.game_data.escalat2,
        jogador1t2: formatPlayert2?.p1?.name ?? "",
        jogador2t2: formatPlayert2?.p2?.name ?? "",
        jogador3t2: formatPlayert2?.p3?.name ?? "",
        jogador4t2: formatPlayert2?.p4?.name ?? "",
        jogador5t2: formatPlayert2?.p5?.name ?? "",
        jogador6t2: formatPlayert2?.p6?.name ?? "",
        jogador7t2: formatPlayert2?.p7?.name ?? "",
        jogador8t2: formatPlayert2?.p8?.name ?? "",
        jogador9t2: formatPlayert2?.p9?.name ?? "",
        jogador10t2: formatPlayert2?.p10?.name ?? "",
        jogador11t2: formatPlayert2?.p11?.name ?? "",
        obs1a: formatPlayert2?.p1?.obs ?? "",
        obs2a: formatPlayert2?.p2?.obs ?? "",
        obs3a: formatPlayert2?.p3?.obs ?? "",
        obs4a: formatPlayert2?.p4?.obs ?? "",
        obs5a: formatPlayert2?.p5?.obs ?? "",
        obs6a: formatPlayert2?.p6?.obs ?? "",
        obs7a: formatPlayert2?.p7?.obs ?? "",
        obs8a: formatPlayert2?.p8?.obs ?? "",
        obs9a: formatPlayert2?.p9?.obs ?? "",
        obs10a: formatPlayert2?.p10?.obs ?? "",
        obs11a: formatPlayert2?.p11?.obs ?? "",
        player1psub1: formatPlayert1Sub?.["1p1"]?.name ?? "",
        player1psub2: formatPlayert1Sub?.["1p2"]?.name ?? "",
        player1psub3: formatPlayert1Sub?.["1p3"]?.name ?? "",
        player2psub1: formatPlayert1Sub?.["2p1"]?.name ?? "",
        player2psub2: formatPlayert1Sub?.["2p2"]?.name ?? "",
        player2psub3: formatPlayert1Sub?.["2p3"]?.name ?? "",
        player3psub1: formatPlayert1Sub?.["3p1"]?.name ?? "",
        player3psub2: formatPlayert1Sub?.["3p2"]?.name ?? "",
        player3psub3: formatPlayert1Sub?.["3p3"]?.name ?? "",
        player4psub1: formatPlayert1Sub?.["4p1"]?.name ?? "",
        player4psub2: formatPlayert1Sub?.["4p2"]?.name ?? "",
        player4psub3: formatPlayert1Sub?.["4p3"]?.name ?? "",
        player5psub1: formatPlayert1Sub?.["5p1"]?.name ?? "",
        player5psub2: formatPlayert1Sub?.["5p2"]?.name ?? "",
        player5psub3: formatPlayert1Sub?.["5p3"]?.name ?? "",
        player6psub1: formatPlayert1Sub?.["6p1"]?.name ?? "",
        player6psub2: formatPlayert1Sub?.["6p2"]?.name ?? "",
        player6psub3: formatPlayert1Sub?.["6p3"]?.name ?? "",
        player7psub1: formatPlayert1Sub?.["7p1"]?.name ?? "",
        player7psub2: formatPlayert1Sub?.["7p2"]?.name ?? "",
        player7psub3: formatPlayert1Sub?.["7p3"]?.name ?? "",
        player8psub1: formatPlayert1Sub?.["8p1"]?.name ?? "",
        player8psub2: formatPlayert1Sub?.["8p2"]?.name ?? "",
        player8psub3: formatPlayert1Sub?.["8p3"]?.name ?? "",
        player9psub1: formatPlayert1Sub?.["9p1"]?.name ?? "",
        player9psub2: formatPlayert1Sub?.["9p2"]?.name ?? "",
        player9psub3: formatPlayert1Sub?.["9p3"]?.name ?? "",
        player10psub1: formatPlayert1Sub?.["10p1"]?.name ?? "",
        player10psub2: formatPlayert1Sub?.["10p2"]?.name ?? "",
        player10psub3: formatPlayert1Sub?.["10p3"]?.name ?? "",
        player11psub1: formatPlayert1Sub?.["11p1"]?.name ?? "",
        player11psub2: formatPlayert1Sub?.["11p2"]?.name ?? "",
        player11psub3: formatPlayert1Sub?.["11p3"]?.name ?? "",
        player1psub1obs: formatPlayert1Sub?.["1p1"]?.obs ?? "",
        player1psub2obs: formatPlayert1Sub?.["1p2"]?.obs ?? "",
        player1psub3obs: formatPlayert1Sub?.["1p3"]?.obs ?? "",
        player2psub1obs: formatPlayert1Sub?.["2p1"]?.obs ?? "",
        player2psub2obs: formatPlayert1Sub?.["2p2"]?.obs ?? "",
        player2psub3obs: formatPlayert1Sub?.["2p3"]?.obs ?? "",
        player3psub1obs: formatPlayert1Sub?.["3p1"]?.obs ?? "",
        player3psub2obs: formatPlayert1Sub?.["3p2"]?.obs ?? "",
        player3psub3obs: formatPlayert1Sub?.["3p3"]?.obs ?? "",
        player4psub1obs: formatPlayert1Sub?.["4p1"]?.obs ?? "",
        player4psub2obs: formatPlayert1Sub?.["4p2"]?.obs ?? "",
        player4psub3obs: formatPlayert1Sub?.["4p3"]?.obs ?? "",
        player5psub1obs: formatPlayert1Sub?.["5p1"]?.obs ?? "",
        player5psub2obs: formatPlayert1Sub?.["5p2"]?.obs ?? "",
        player5psub3obs: formatPlayert1Sub?.["5p3"]?.obs ?? "",
        player6psub1obs: formatPlayert1Sub?.["6p1"]?.obs ?? "",
        player6psub2obs: formatPlayert1Sub?.["6p2"]?.obs ?? "",
        player6psub3obs: formatPlayert1Sub?.["6p3"]?.obs ?? "",
        player7psub1obs: formatPlayert1Sub?.["7p1"]?.obs ?? "",
        player7psub2obs: formatPlayert1Sub?.["7p2"]?.obs ?? "",
        player7psub3obs: formatPlayert1Sub?.["7p3"]?.obs ?? "",
        player8psub1obs: formatPlayert1Sub?.["8p1"]?.obs ?? "",
        player8psub2obs: formatPlayert1Sub?.["8p2"]?.obs ?? "",
        player8psub3obs: formatPlayert1Sub?.["8p3"]?.obs ?? "",
        player9psub1obs: formatPlayert1Sub?.["9p1"]?.obs ?? "",
        player9psub2obs: formatPlayert1Sub?.["9p2"]?.obs ?? "",
        player9psub3obs: formatPlayert1Sub?.["9p3"]?.obs ?? "",
        player10psub1obs: formatPlayert1Sub?.["10p1"]?.obs ?? "",
        player10psub2obs: formatPlayert1Sub?.["10p2"]?.obs ?? "",
        player10psub3obs: formatPlayert1Sub?.["10p3"]?.obs ?? "",
        player11psub1obs: formatPlayert1Sub?.["11p1"]?.obs ?? "",
        player11psub2obs: formatPlayert1Sub?.["11p2"]?.obs ?? "",
        player11psub3obs: formatPlayert1Sub?.["11p3"]?.obs ?? "",
        player1asub1: formatPlayert2Sub?.["1p1"]?.name ?? "",
        player1asub2: formatPlayert2Sub?.["1p2"]?.name ?? "",
        player1asub3: formatPlayert2Sub?.["1p3"]?.name ?? "",
        player2asub1: formatPlayert2Sub?.["2p1"]?.name ?? "",
        player2asub2: formatPlayert2Sub?.["2p2"]?.name ?? "",
        player2asub3: formatPlayert2Sub?.["2p3"]?.name ?? "",
        player3asub1: formatPlayert2Sub?.["3p1"]?.name ?? "",
        player3asub2: formatPlayert2Sub?.["3p2"]?.name ?? "",
        player3asub3: formatPlayert2Sub?.["3p3"]?.name ?? "",
        player4asub1: formatPlayert2Sub?.["4p1"]?.name ?? "",
        player4asub2: formatPlayert2Sub?.["4p2"]?.name ?? "",
        player4asub3: formatPlayert2Sub?.["4p3"]?.name ?? "",
        player5asub1: formatPlayert2Sub?.["5p1"]?.name ?? "",
        player5asub2: formatPlayert2Sub?.["5p2"]?.name ?? "",
        player5asub3: formatPlayert2Sub?.["5p3"]?.name ?? "",
        player6asub1: formatPlayert2Sub?.["6p1"]?.name ?? "",
        player6asub2: formatPlayert2Sub?.["6p2"]?.name ?? "",
        player6asub3: formatPlayert2Sub?.["6p3"]?.name ?? "",
        player7asub1: formatPlayert2Sub?.["7p1"]?.name ?? "",
        player7asub2: formatPlayert2Sub?.["7p2"]?.name ?? "",
        player7asub3: formatPlayert2Sub?.["7p3"]?.name ?? "",
        player8asub1: formatPlayert2Sub?.["8p1"]?.name ?? "",
        player8asub2: formatPlayert2Sub?.["8p2"]?.name ?? "",
        player8asub3: formatPlayert2Sub?.["8p3"]?.name ?? "",
        player9asub1: formatPlayert2Sub?.["9p1"]?.name ?? "",
        player9asub2: formatPlayert2Sub?.["9p2"]?.name ?? "",
        player9asub3: formatPlayert2Sub?.["9p3"]?.name ?? "",
        player10asub1: formatPlayert2Sub?.["10p1"]?.name ?? "",
        player10asub2: formatPlayert2Sub?.["10p2"]?.name ?? "",
        player10asub3: formatPlayert2Sub?.["10p3"]?.name ?? "",
        player11asub1: formatPlayert2Sub?.["11p1"]?.name ?? "",
        player11asub2: formatPlayert2Sub?.["11p2"]?.name ?? "",
        player11asub3: formatPlayert2Sub?.["11p3"]?.name ?? "",
        player1asub1obs: formatPlayert2Sub?.["1p1"]?.obs ?? "",
        player1asub2obs: formatPlayert2Sub?.["1p2"]?.obs ?? "",
        player1asub3obs: formatPlayert2Sub?.["1p3"]?.obs ?? "",
        player2asub1obs: formatPlayert2Sub?.["2p1"]?.obs ?? "",
        player2asub2obs: formatPlayert2Sub?.["2p2"]?.obs ?? "",
        player2asub3obs: formatPlayert2Sub?.["2p3"]?.obs ?? "",
        player3asub1obs: formatPlayert2Sub?.["3p1"]?.obs ?? "",
        player3asub2obs: formatPlayert2Sub?.["3p2"]?.obs ?? "",
        player3asub3obs: formatPlayert2Sub?.["3p3"]?.obs ?? "",
        player4asub1obs: formatPlayert2Sub?.["4p1"]?.obs ?? "",
        player4asub2obs: formatPlayert2Sub?.["4p2"]?.obs ?? "",
        player4asub3obs: formatPlayert2Sub?.["4p3"]?.obs ?? "",
        player5asub1obs: formatPlayert2Sub?.["5p1"]?.obs ?? "",
        player5asub2obs: formatPlayert2Sub?.["5p2"]?.obs ?? "",
        player5asub3obs: formatPlayert2Sub?.["5p3"]?.obs ?? "",
        player6asub1obs: formatPlayert2Sub?.["6p1"]?.obs ?? "",
        player6asub2obs: formatPlayert2Sub?.["6p2"]?.obs ?? "",
        player6asub3obs: formatPlayert2Sub?.["6p3"]?.obs ?? "",
        player7asub1obs: formatPlayert2Sub?.["7p1"]?.obs ?? "",
        player7asub2obs: formatPlayert2Sub?.["7p2"]?.obs ?? "",
        player7asub3obs: formatPlayert2Sub?.["7p3"]?.obs ?? "",
        player8asub1obs: formatPlayert2Sub?.["8p1"]?.obs ?? "",
        player8asub2obs: formatPlayert2Sub?.["8p2"]?.obs ?? "",
        player8asub3obs: formatPlayert2Sub?.["8p3"]?.obs ?? "",
        player9asub1obs: formatPlayert2Sub?.["9p1"]?.obs ?? "",
        player9asub2obs: formatPlayert2Sub?.["9p2"]?.obs ?? "",
        player9asub3obs: formatPlayert2Sub?.["9p3"]?.obs ?? "",
        player10asub1obs: formatPlayert2Sub?.["10p1"]?.obs ?? "",
        player10asub2obs: formatPlayert2Sub?.["10p2"]?.obs ?? "",
        player10asub3obs: formatPlayert2Sub?.["10p3"]?.obs ?? "",
        player11asub1obs: formatPlayert2Sub?.["11p1"]?.obs ?? "",
        player11asub2obs: formatPlayert2Sub?.["11p2"]?.obs ?? "",
        player11asub3obs: formatPlayert2Sub?.["11p3"]?.obs ?? "",
        comentario: datasheetData.data?.game_data.html,
        publico: datasheetData.data?.game_data.public,
      });
    } catch (err: any) {
      handleSnackbar(err.message);
    }
  };

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
  };

  const getGameInfo = async () => {
    try {
      showLoading();

      const { data: opponentsData } = await api.get("/opponents");
      const { data: coachesData } = await api.get("/coaches");
      const { data: coachesOpponentsData } = await api.get(
        "/coaches-opponents"
      );
      const { data: refereesData } = await api.get("/referees");
      const { data: assistantData } = await api.get("/assistants");
      const { data: secondAssistantData } = await api.get("/assistants-second");
      const { data: playersData } = await api.get("/players");
      const { data: stadiumsData } = await api.get("/stadiums");
      const { data: championshipsData } = await api.get("/championships");
      const { data: stagesData } = await api.get("/stages");
      const { data: countriesData } = await api.get("/countries");

      setOptionsForm({
        opponentsData,
        coachesData,
        coachesOpponentsData,
        refereesData,
        assistantData,
        secondAssistantData,
        playersData,
        championshipsData,
        stagesData,
        stadiumsData,
        countriesData,
      });
    } catch (err) {
    } finally {
      closeLoading();
    }
  };

  const handleRadioChangePeriodoDia = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFields((prevState) => ({
      ...prevState,
      periodo: (event.target as HTMLInputElement).value,
    }));
  };

  const handleRadioChangeMandante = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFields((prevState) => ({
      ...prevState,
      localdojogo: (event.target as HTMLInputElement).value,
    }));
  };

  const handleChangeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields((prevState) => ({
      ...prevState,
      stageCheck: event.target.checked,
    }));
  };

  const handleChangeAccordeon =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const opponentImage = optionsForm?.opponentsData?.data.find(
    (opponent) => opponent.name === formFields.time2
  );

  const handleChangeSub = (value: string) => {
    console.log(value);
    console.log(subs);
    const find = subs.includes(value);
    if (find) {
      const filter = subs.filter((item) => item !== value);
      setSubs(filter);

      return;
    }

    setSubs((prev) => [...prev, value]);
  };

  const handleForm = async () => {
    try {
      showLoading();
      console.log(formFields);

      await api.post("technical-files", formFields);

      enqueueSnackbar("Ficha inserida com sucesso!", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });

      navigate("/backoffice/fichas-tecnicas");
    } catch (e) {
      handleSnackbar("Error ao inserir ficha.");
    } finally {
      closeLoading();
    }
  };

  const team1render = React.useMemo(() => {
    return Array.from({ length: 11 }).map((_, key) => {
      const p = sheet?.lineup_palmeiras.find(
        (item) => key + 1 === item.position && !item.isReplacement
      );
      let defaultValuePlayerName = p?.name;
      let infos = "";

      if (p?.cards && p?.cards?.length > 0) {
        p?.cards?.map((card) => {
          infos += `{${card.card} ${card.time} do ${card.period}}`;
        });
      }

      if (p?.goals && p?.goals?.length > 0) {
        p.goals.map((goal: any, index: any) => {
          infos += index === 0 ? "[" : "";
          infos +=
            `${goal.time} do ${goal.period}{${goal.gol}}` +
            (index !== (p?.goals?.length ?? 1) - 1 ? ";" : "");
          infos += index === (p?.goals?.length ?? 1) - 1 ? "]" : "";
        });
      }

      if (infos) {
        defaultValuePlayerName += `*${infos}`;
      }

      const defaultValuePlayerNames: string[] | null = [];
      const replacements = sheet?.lineup_palmeiras.filter(
        (item) => key + 1 === item.position && item.isReplacement
      );
      replacements?.map((player) => {
        let infos = "";

        if (player?.cards && player?.cards?.length > 0) {
          player?.cards?.map((card) => {
            infos += `{${card.card} ${card.time} do ${card.period}}`;
          });
        }

        if (player?.goals && player?.goals?.length > 0) {
          player?.goals?.map((goal: any, index: any) => {
            infos += index === 0 ? "[" : "";
            infos +=
              `${goal.time} do ${goal.period}{${goal.gol}}` +
              (index !== (p?.goals?.length ?? 1) - 1 ? ";" : "");
            infos += index === (p?.goals?.length ?? 1) - 1 ? "]" : "";
          });
        }

        defaultValuePlayerNames.push(player.name + infos);
      });

      return (
        <div key={Number(key)}>
          <div className="division">
            <Autocomplete
              getOptionLabel={(option: any) => option.name}
              autoHighlight
              options={
                optionsForm?.playersData?.data.map((player) => ({
                  name: `${player.name}${
                    player.description !== "" ? `|${player.description}` : ""
                  }`,
                })) ?? []
              }
              defaultValue={{ name: defaultValuePlayerName ?? "" }}
              freeSolo={true}
              onChange={(value: any, newValue: any) => {
                if (newValue.name.split("|").length > 1) {
                  const playerName = newValue.name.split("|")[0];
                  const obs = newValue.name.split("|")[1];
                  if (obs.split("*").length > 1) {
                    setFormFields((prevState) => ({
                      ...prevState,
                      [`obs${key + 1}p`]: obs.split("*")[0],
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`jogador${key + 1}t1`]: `${playerName}*${
                        obs.split("*")[1]
                      }`,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`obs${key + 1}p`]: obs,
                  }));

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`jogador${key + 1}t1`]: playerName,
                  }));

                  return;
                }

                setFormFields((prevState) => ({
                  ...prevState,
                  [`jogador${key + 1}t1`]: newValue.name,
                }));
              }}
              renderInput={(params) => (
                <>
                  <label className="label">Jogador {key + 1}:</label>
                  <TextField
                    {...params}
                    variant="outlined"
                    onChange={(value: any) => {
                      if (value.target.value.split("|").length > 1) {
                        const playerName = value.target.value.split("|")[0];
                        const obs = value.target.value.split("|")[1];
                        if (obs.split("*").length > 1) {
                          setFormFields((prevState) => ({
                            ...prevState,
                            [`obs${key + 1}p`]: obs.split("*")[0],
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`jogador${key + 1}t1`]: `${playerName}*${
                              obs.split("*")[1]
                            }`,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`obs${key + 1}p`]: obs,
                        }));

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`jogador${key + 1}t1`]: playerName,
                        }));

                        return;
                      }

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`jogador${key + 1}t1`]: value.target.value,
                      }));
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                        borderBottom: "2px solid #E3E4E7",
                        borderRadius: 0,
                      },
                    }}
                    fullWidth
                    label=""
                    placeholder={`Jogador ${key + 1}`}
                  />
                </>
              )}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleChangeSub(`player${key + 1}psub`)}
                    checked={subs.includes(`player${key + 1}psub`)}
                  />
                }
                label="Sub"
              />
            </FormGroup>
          </div>
          {subs.includes(`player${key + 1}psub`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[0] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.name.split("|").length > 1) {
                    const playerName = newValue.name.split("|")[0];
                    const obs = newValue.name.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub2obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub2`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub2obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub2`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}psub2`]: newValue.name,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 1:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub1obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub1`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub1obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub1`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}psub1`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 1:"
                    />
                  </>
                )}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChangeSub(`player${key + 1}psub1`)}
                      checked={subs.includes(`player${key + 1}psub1`)}
                    />
                  }
                  label="Sub"
                />
              </FormGroup>
            </div>
          )}
          {subs.includes(`player${key + 1}psub1`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[1] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.name.split("|").length > 1) {
                    const playerName = newValue.name.split("|")[0];
                    const obs = newValue.name.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub2obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub2`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub2obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub2`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}psub2`]: newValue.name,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 2:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub2obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub2`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub2obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub2`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}psub2`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 2:"
                    />
                  </>
                )}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChangeSub(`player${key + 1}psub2`)}
                      checked={subs.includes(`player${key + 1}psub2`)}
                    />
                  }
                  label="Sub"
                />
              </FormGroup>
            </div>
          )}
          {subs.includes(`player${key + 1}psub2`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[2] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.split("|").length > 1) {
                    const playerName = newValue.split("|")[0];
                    const obs = newValue.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub3obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}psub3`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub3obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}psub3`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}psub3`]: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 3:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub3obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}psub3`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub3obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}psub3`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}psub3`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 2:"
                    />
                  </>
                )}
              />
            </div>
          )}
        </div>
      );
    });
  }, [optionsForm, subs]);

  const team2render = React.useMemo(() => {
    return Array.from({ length: 11 }).map((_, key) => {
      const p = sheet?.lineup_adversary.find(
        (item) => key + 1 === item.position && !item.isReplacement
      );
      let defaultValuePlayerName = p?.name;
      let infos = "";

      if (p?.cards && p?.cards?.length > 0) {
        p?.cards?.map((card) => {
          infos += `{${card.card} ${card.time} do ${card.period}}`;
        });
      }

      if (p?.goals && p?.goals?.length > 0) {
        p.goals.map((goal: any, index: any) => {
          infos += index === 0 ? "[" : "";
          infos +=
            `${goal.time} do ${goal.period}{${goal.gol}}` +
            (index !== (p?.goals?.length ?? 1) - 1 ? ";" : "");
          infos += index === (p?.goals?.length ?? 1) - 1 ? "]" : "";
        });
      }

      if (infos) {
        defaultValuePlayerName += `*${infos}`;
      }

      const defaultValuePlayerNames: string[] | null = [];
      const replacements = sheet?.lineup_adversary.filter(
        (item) => key + 1 === item.position && item.isReplacement
      );
      replacements?.map((player) => {
        let infos = "";

        if (player?.cards && player?.cards?.length > 0) {
          player?.cards?.map((card) => {
            infos += `{${card.card} ${card.time} do ${card.period}}`;
          });
        }

        if (player?.goals && player?.goals?.length > 0) {
          player?.goals?.map((goal: any, index: any) => {
            infos += index === 0 ? "[" : "";
            infos +=
              `${goal.time} do ${goal.period}{${goal.gol}}` +
              (index !== (p?.goals?.length ?? 1) - 1 ? ";" : "");
            infos += index === (p?.goals?.length ?? 1) - 1 ? "]" : "";
          });
        }

        defaultValuePlayerNames.push(player.name + infos);
      });

      return (
        <div key={Number(key)}>
          <div className="division">
            <Autocomplete
              getOptionLabel={(option: any) => option.name}
              autoHighlight
              options={
                optionsForm?.playersData?.data.map((player) => ({
                  name: `${player.name}${
                    player.description !== "" ? `|${player.description}` : ""
                  }`,
                })) ?? []
              }
              defaultValue={{ name: defaultValuePlayerName ?? "" }}
              freeSolo={true}
              onChange={(value: any, newValue: any) => {
                if (newValue.name.split("|").length > 1) {
                  const playerName = newValue.name.split("|")[0];
                  const obs = newValue.name.split("|")[1];
                  if (obs.split("*").length > 1) {
                    setFormFields((prevState) => ({
                      ...prevState,
                      [`obs${key + 1}a`]: obs.split("*")[0],
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`jogador${key + 1}t2`]: `${playerName}*${
                        obs.split("*")[1]
                      }`,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`obs${key + 1}a`]: obs,
                  }));

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`jogador${key + 1}t2`]: playerName,
                  }));

                  return;
                }

                setFormFields((prevState) => ({
                  ...prevState,
                  [`jogador${key + 1}t2`]: newValue.name,
                }));
              }}
              renderInput={(params) => (
                <>
                  <label className="label">Jogador {key + 1}:</label>
                  <TextField
                    {...params}
                    variant="outlined"
                    onChange={(value: any) => {
                      if (value.target.value.split("|").length > 1) {
                        const playerName = value.target.value.split("|")[0];
                        const obs = value.target.value.split("|")[1];
                        if (obs.split("*").length > 1) {
                          setFormFields((prevState) => ({
                            ...prevState,
                            [`obs${key + 1}a`]: obs.split("*")[0],
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`jogador${key + 1}t2`]: `${playerName}*${
                              obs.split("*")[1]
                            }`,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`obs${key + 1}a`]: obs,
                        }));

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`jogador${key + 1}t2`]: playerName,
                        }));

                        return;
                      }

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`jogador${key + 1}t2`]: value.target.value,
                      }));
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                        borderBottom: "2px solid #E3E4E7",
                        borderRadius: 0,
                      },
                    }}
                    fullWidth
                    label=""
                    placeholder={`Jogador ${key + 1}`}
                  />
                </>
              )}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleChangeSub(`player${key + 1}asub`)}
                    checked={subs.includes(`player${key + 1}asub`)}
                  />
                }
                label="Sub"
              />
            </FormGroup>
          </div>
          {subs.includes(`player${key + 1}asub`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[0] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.name.split("|").length > 1) {
                    const playerName = newValue.name.split("|")[0];
                    const obs = newValue.name.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub2obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub2`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub2obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub2`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}asub2`]: newValue.name,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 1:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub1obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub1`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub1obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub1`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}asub1`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 1:"
                    />
                  </>
                )}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChangeSub(`player${key + 1}asub1`)}
                      checked={subs.includes(`player${key + 1}asub1`)}
                    />
                  }
                  label="Sub"
                />
              </FormGroup>
            </div>
          )}
          {subs.includes(`player${key + 1}asub1`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[1] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.name.split("|").length > 1) {
                    const playerName = newValue.name.split("|")[0];
                    const obs = newValue.name.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub2obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub2`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub2obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub2`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}asub2`]: newValue.name,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 2:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub2obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub2`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub2obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub2`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}asub2`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 2:"
                    />
                  </>
                )}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChangeSub(`player${key + 1}asub2`)}
                      checked={subs.includes(`player${key + 1}asub2`)}
                    />
                  }
                  label="Sub"
                />
              </FormGroup>
            </div>
          )}
          {subs.includes(`player${key + 1}asub2`) && (
            <div className="division">
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.playersData?.data.map((player) => ({
                    name: `${player.name}${
                      player.description !== "" ? `|${player.description}` : ""
                    }`,
                  })) ?? []
                }
                defaultValue={{ name: defaultValuePlayerNames[2] ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  if (newValue.name.split("|").length > 1) {
                    const playerName = newValue.name.split("|")[0];
                    const obs = newValue.name.split("|")[1];
                    if (obs.split("*").length > 1) {
                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub3obs`]: obs.split("*")[0],
                      }));

                      setFormFields((prevState) => ({
                        ...prevState,
                        [`player${key + 1}asub3`]: `${playerName}*${
                          obs.split("*")[1]
                        }`,
                      }));

                      return;
                    }

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub3obs`]: obs,
                    }));

                    setFormFields((prevState) => ({
                      ...prevState,
                      [`player${key + 1}asub3`]: playerName,
                    }));

                    return;
                  }

                  setFormFields((prevState) => ({
                    ...prevState,
                    [`player${key + 1}asub3`]: newValue.name,
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Substituto 3:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        if (value.target.value.split("|").length > 1) {
                          const playerName = value.target.value.split("|")[0];
                          const obs = value.target.value.split("|")[1];
                          if (obs.split("*").length > 1) {
                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub3obs`]: obs.split("*")[0],
                            }));

                            setFormFields((prevState) => ({
                              ...prevState,
                              [`player${key + 1}asub3`]: `${playerName}*${
                                obs.split("*")[1]
                              }`,
                            }));

                            return;
                          }

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub3obs`]: obs,
                          }));

                          setFormFields((prevState) => ({
                            ...prevState,
                            [`player${key + 1}asub3`]: playerName,
                          }));

                          return;
                        }

                        setFormFields((prevState) => ({
                          ...prevState,
                          [`player${key + 1}asub3`]: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Substituto 2:"
                    />
                  </>
                )}
              />
            </div>
          )}
        </div>
      );
    });
  }, [optionsForm, subs]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleForm();
        }}
      >
        <div className="division">
          <div>
            {optionsForm?.opponentsData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.opponentsData?.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.name_opponent ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    time2: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Adversário:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          time2: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Adversário"
                    />
                  </>
                )}
              />
            )}
          </div>
          <div>
            {optionsForm?.coachesOpponentsData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.coachesOpponentsData?.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.coach_adversary ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    tecnico2: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Técnico Adversário:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          tecnico2: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Técnico Adversário"
                    />
                  </>
                )}
              />
            )}
          </div>
        </div>
        <div className="division">
          <div>
            {optionsForm?.coachesData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm.coachesData.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.coach_palmeiras ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    tecnico: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Técnico (PALMEIRAS):</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          tecnico: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Técnico (PALMEIRAS):"
                    />
                  </>
                )}
              />
            )}
          </div>
          <div>
            {optionsForm?.refereesData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.refereesData?.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.referee ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    juiz: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Árbitro:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          juiz: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Árbitro:"
                    />
                  </>
                )}
              />
            )}
          </div>
        </div>
        <div className="division">
          <div>
            {optionsForm?.assistantData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.assistantData?.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.assistant ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    auxiliar1: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Auxiliar 1:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          auxiliar1: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Auxiliar 1:"
                    />
                  </>
                )}
              />
            )}
          </div>
          <div>
            {optionsForm?.secondAssistantData?.data && (
              <Autocomplete
                getOptionLabel={(option: any) => option.name}
                autoHighlight
                options={
                  optionsForm?.secondAssistantData?.data.map((player) => ({
                    name: `${player.name}`,
                  })) ?? []
                }
                defaultValue={{ name: sheet?.game_data.second_assistant ?? "" }}
                freeSolo={true}
                onChange={(value: any, newValue: any) => {
                  setFormFields((prevState) => ({
                    ...prevState,
                    auxiliar2: newValue?.name ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <>
                    <label className="label">Auxiliar 2:</label>
                    <TextField
                      {...params}
                      variant="outlined"
                      onChange={(value: any) => {
                        setFormFields((prevState) => ({
                          ...prevState,
                          auxiliar2: value.target.value,
                        }));
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          borderBottom: "2px solid #E3E4E7",
                          borderRadius: 0,
                        },
                      }}
                      fullWidth
                      label=""
                      placeholder="Auxiliar 2:"
                    />
                  </>
                )}
              />
            )}
          </div>
        </div>
        <Label>Escalação Palmeiras</Label>
        <Editor
          apiKey="4qvjh8eh1z59c5at9ohyis7rt212hruzifqy7fumitj2epzf"
          onInit={(evt: any, editor) => {
            editorRef.current = editor;
          }}
          onEditorChange={(text) =>
            setFormFields((prevState) => ({
              ...prevState,
              escalat1: text,
            }))
          }
          value={formFields.escalat1}
          init={{
            plugins:
              "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help  charmap quickbars emoticons",
            tinydrive_token_provider: "URL_TO_YOUR_TOKEN_PROVIDER",
            tinydrive_dropbox_app_key: "YOUR_DROPBOX_APP_KEY",
            tinydrive_google_drive_key: "YOUR_GOOGLE_DRIVE_KEY",
            tinydrive_google_drive_client_id: "YOUR_GOOGLE_DRIVE_CLIENT_ID",
            mobile: {
              plugins:
                "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
            },
            menu: {
              tc: {
                title: "Comments",
                items: "addcomment showcomments deleteallconversations",
              },
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
            autosave_ask_before_unload: true,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            templates: [
              {
                title: "New Table",
                description: "creates a new table",
                content:
                  '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
              },
              {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
              },
              {
                title: "New list with dates",
                description: "New List with dates",
                content:
                  '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
              },
            ],
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            spellchecker_ignore_list: ["Ephox", "Moxiecode"],
            content_style: ".mymention{ color: gray; }",
            contextmenu: "link image imagetools table configure",
            a11y_advanced_options: true,
            mentions_selector: ".mymention",
            mentions_item_type: "profile",
            resize: false,
            language: "pt_BR",
            mceInsertContent: false,
          }}
        />
        {optionsForm?.playersData?.data && team1render}
        <Label>Escalação Adversaria</Label>
        <Editor
          apiKey="4qvjh8eh1z59c5at9ohyis7rt212hruzifqy7fumitj2epzf"
          onInit={(evt: any, editor) => {
            editorRef.current = editor;
          }}
          onEditorChange={(text) =>
            setFormFields((prevState) => ({
              ...prevState,
              escalat2: text,
            }))
          }
          value={formFields.escalat2}
          init={{
            plugins:
              "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help  charmap quickbars emoticons",
            tinydrive_token_provider: "URL_TO_YOUR_TOKEN_PROVIDER",
            tinydrive_dropbox_app_key: "YOUR_DROPBOX_APP_KEY",
            tinydrive_google_drive_key: "YOUR_GOOGLE_DRIVE_KEY",
            tinydrive_google_drive_client_id: "YOUR_GOOGLE_DRIVE_CLIENT_ID",
            mobile: {
              plugins:
                "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
            },
            menu: {
              tc: {
                title: "Comments",
                items: "addcomment showcomments deleteallconversations",
              },
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
            autosave_ask_before_unload: true,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            templates: [
              {
                title: "New Table",
                description: "creates a new table",
                content:
                  '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
              },
              {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
              },
              {
                title: "New list with dates",
                description: "New List with dates",
                content:
                  '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
              },
            ],
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            spellchecker_ignore_list: ["Ephox", "Moxiecode"],
            content_style: ".mymention{ color: gray; }",
            contextmenu: "link image imagetools table configure",
            a11y_advanced_options: true,
            mentions_selector: ".mymention",
            mentions_item_type: "profile",
            resize: false,
            language: "pt_BR",
            mceInsertContent: false,
          }}
        />
        {optionsForm?.playersData?.data && team2render}
        <div className="division">
          <div>
            <label className="label">Placar:</label>
            <div id="scoreboard">
              <div>
                <div className="team-logo" id="palmeiras-logo">
                  {/* <Image width={40} height={40} src={SvgPal} /> */}
                </div>
                <Input
                  type="number"
                  name="placar1"
                  id="placar1"
                  onChange={(e) => {
                    setFormFields((prevState) => ({
                      ...prevState,
                      placar1: e.target.value ?? "",
                    }));
                  }}
                  value={formFields.placar1}
                  placeholder="0"
                  required
                />
              </div>
              <span>X</span>
              <div>
                <Input
                  type="number"
                  name="placar2"
                  id="placar2"
                  onChange={(e) => {
                    setFormFields((prevState) => ({
                      ...prevState,
                      placar2: e.target.value,
                    }));
                  }}
                  value={formFields.placar2}
                  placeholder="0"
                  required
                />
                {opponentImage && (
                  <div className="team-logo" id="opponent-logo">
                    <img src={opponentImage.image} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChangeAccordeon("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Estádio</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="division">
              <div>
                {optionsForm?.stadiumsData?.data && (
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name}
                    autoHighlight
                    options={
                      optionsForm?.stadiumsData?.data.map((player) => ({
                        name: `${player.name}`,
                      })) ?? []
                    }
                    defaultValue={{ name: formFields.estadio ?? "" }}
                    freeSolo={true}
                    onChange={(value: any, newValue: any) => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        estadio: newValue?.name ?? "",
                      }));
                    }}
                    renderInput={(params) => (
                      <>
                        <label className="label">Auxiliar 2:</label>
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(value: any) => {
                            setFormFields((prevState) => ({
                              ...prevState,
                              estadio: value.target.value,
                            }));
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                              borderBottom: "2px solid #E3E4E7",
                              borderRadius: 0,
                            },
                          }}
                          fullWidth
                          label=""
                          placeholder="Estádio"
                        />
                      </>
                    )}
                  />
                )}
              </div>
              <div>
                <label className="label">Público</label>
                <div className="input-div-flex">
                  <Input
                    type="number"
                    name="publico"
                    id="publico"
                    onChange={(e) => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        publico: e.target.value,
                      }));
                    }}
                    value={formFields.publico}
                    placeholder="0"
                    disabled={formFields.publicOption === "0"}
                  />
                </div>
              </div>
            </div>
            <div className="division">
              <div>
                <label className="label">Mandante:</label>
                <FormControl sx={{ m: 3 }} variant="standard" fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={formFields.localdojogo}
                    onChange={handleRadioChangeMandante}
                  >
                    <FormControlLabel
                      value="C"
                      control={<Radio />}
                      label="Casa"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Fora"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="Neutro"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <label className="label">Período:</label>
                <FormControl sx={{ m: 3 }} variant="standard" fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={formFields.periodo}
                    onChange={handleRadioChangePeriodoDia}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Manha"
                    />
                    <FormControlLabel
                      value="T"
                      control={<Radio />}
                      label="Tarde"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="Noite"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="division">
              <div>
                <div>
                  {optionsForm?.countriesData?.data && (
                    <SearchAucomplete
                      options={optionsForm?.countriesData?.data}
                      defaultValue={formFields.pais}
                      setInputValue={(value) =>
                        setFormFields((prevState) => ({
                          ...prevState,
                          pais: value,
                        }))
                      }
                      inputComponentProps={{
                        type: "search",
                        label: "País:",
                        placeholder: "",
                      }}
                    />
                  )}
                </div>
              </div>
              <div></div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChangeAccordeon("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Competição</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="division">
              <div>
                {optionsForm?.championshipsData?.data && (
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name}
                    autoHighlight
                    options={
                      optionsForm?.championshipsData?.data.map((player) => ({
                        name: `${player.name}`,
                      })) ?? []
                    }
                    defaultValue={{ name: formFields.tipo ?? "" }}
                    freeSolo={true}
                    onChange={(value: any, newValue: any) => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        tipo: newValue?.name ?? "",
                      }));
                    }}
                    renderInput={(params) => (
                      <>
                        <label className="label">Competição:</label>
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(value: any) => {
                            setFormFields((prevState) => ({
                              ...prevState,
                              tipo: value.target.value,
                            }));
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                              borderBottom: "2px solid #E3E4E7",
                              borderRadius: 0,
                            },
                          }}
                          fullWidth
                          label=""
                          placeholder="Competição"
                        />
                      </>
                    )}
                  />
                )}
              </div>
              <div>
                {optionsForm?.stagesData?.data && (
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name}
                    autoHighlight
                    options={
                      optionsForm?.stagesData?.data.map((player) => ({
                        name: `${player.name}`,
                      })) ?? []
                    }
                    defaultValue={{ name: formFields.etapa ?? "" }}
                    freeSolo={true}
                    onChange={(value: any, newValue: any) => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        etapa: newValue?.name ?? "",
                      }));
                    }}
                    renderInput={(params) => (
                      <>
                        <label className="label">Fase:</label>
                        <TextField
                          {...params}
                          variant="outlined"
                          onChange={(value: any) => {
                            setFormFields((prevState) => ({
                              ...prevState,
                              etapa: value.target.value,
                            }));
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                              borderBottom: "2px solid #E3E4E7",
                              borderRadius: 0,
                            },
                          }}
                          fullWidth
                          label=""
                          placeholder="Fase"
                        />
                      </>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="division">
              <div>
                <label className="label">Data:</label>
                <div id="scoreboard">
                  <Input
                    name="data"
                    type="data"
                    value={formFields.data}
                    onChange={(e) => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        data: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div>
                <FormControlLabel
                  checked={formFields.campeao === "s"}
                  control={
                    <Checkbox
                      onChange={(event) =>
                        setFormFields((prev) => ({
                          ...prev,
                          campeao: event.target.checked ? "s" : "n",
                        }))
                      }
                      checked={formFields.campeao == "s"}
                    />
                  }
                  label="Jogo em que foi campeão"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Label>Comentario:</Label>
        <Editor
          apiKey="4qvjh8eh1z59c5at9ohyis7rt212hruzifqy7fumitj2epzf"
          onInit={(evt: any, editor) => {
            editorRef.current = editor;
          }}
          onEditorChange={(text) =>
            setFormFields((prevState) => ({
              ...prevState,
              comentario: text,
            }))
          }
          value={formFields.comentario}
          init={{
            plugins:
              "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help  charmap quickbars emoticons",
            tinydrive_token_provider: "URL_TO_YOUR_TOKEN_PROVIDER",
            tinydrive_dropbox_app_key: "YOUR_DROPBOX_APP_KEY",
            tinydrive_google_drive_key: "YOUR_GOOGLE_DRIVE_KEY",
            tinydrive_google_drive_client_id: "YOUR_GOOGLE_DRIVE_CLIENT_ID",
            mobile: {
              plugins:
                "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
            },
            menu: {
              tc: {
                title: "Comments",
                items: "addcomment showcomments deleteallconversations",
              },
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
            autosave_ask_before_unload: true,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            templates: [
              {
                title: "New Table",
                description: "creates a new table",
                content:
                  '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
              },
              {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
              },
              {
                title: "New list with dates",
                description: "New List with dates",
                content:
                  '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
              },
            ],
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            spellchecker_ignore_list: ["Ephox", "Moxiecode"],
            content_style: ".mymention{ color: gray; }",
            contextmenu: "link image imagetools table configure",
            a11y_advanced_options: true,
            mentions_selector: ".mymention",
            mentions_item_type: "profile",
            resize: false,
            language: "pt_BR",
            mceInsertContent: false,
          }}
        />

        <ButtonContainer>
          <Button disabled={loading} type="submit">
            {loading ? "Carregando" : "Enviar"}
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default FichasTecnicas;
